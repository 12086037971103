<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <div
      class="mt-10"
      style="background:white; min-height:700px"
    >
      <v-row class="d-flex justify-center">
        <v-col
          cols="4"
          class="ma-5 d-flex"
          style="background:#B6EDFF; border-radius:30px"
        >
          <v-col
            cols="6"
            class="pa-0"
          >
            <span class="mr-5 blue--text">{{ $t('messages.managerIdCustomer') }}</span>
            {{ customerDetail.code }}
          </v-col>
          <v-col
            cols="6"
            class="pa-0"
          >
            <span class="mr-5 blue--text">{{ $t('messages.factoryName') }}</span>
            {{ name }}
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-row class="d-flex justify-center align-center">
            <v-col cols="2">
              <span>{{ $t('messages.applicablePeriod') }}</span>
            </v-col>
            <v-col cols="3">
              <v-menu
                ref="menuStart"
                v-model="menuStart"
                :close-on-content-click="false"
                :return-value.sync="startAt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startAt"
                    prepend-icon="mdi-calendar"
                    disabled
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="startAt"
                  locale="ja"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menuStart = false"
                  >
                    {{ $t('messages.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuStart.save(startAt)"
                  >
                    {{ $t('messages.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="1"
              class="d-flex justify-center"
            >
              ~
            </v-col>
            <v-col cols="3">
              <v-menu
                ref="menuEnd"
                v-model="menuEnd"
                :close-on-content-click="false"
                :return-value.sync="endAt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endAt"
                    prepend-icon="mdi-calendar"
                    disabled
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="endAt"
                  locale="ja"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menuEnd = false"
                  >
                    {{ $t('messages.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuEnd.save(endAt)"
                  >
                    {{ $t('messages.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row class="d-flex justify-center align-center">
            <v-col cols="2">
              <span>{{ $t('messages.applicableMethod') }}</span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex align-center"
            >
              <v-select
                v-model="type"
                outlined
                :items="methodsList"
                item-text="text"
                item-value="value"
                readonly
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="d-flex">
        <v-col
          class="d-flex justify-end"
          cols="11"
        >
          <v-btn
            color="#5CA6D2"
            class="mr-16 white--text"
            style="width:120px"
            @click="$router.push('/revenue-history/' + $route.params.id)"
          >
            {{ $t('button.historyRevenue') }}
          </v-btn>
          <v-btn
            color="#5CA6D2"
            class="mr-16 white--text"
            style="width:120px"
            @click="$router.push('/revenue-add/' + $route.params.id)"
          >
            {{ $t('button.addNewItem') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="8"
          style="border:1px solid #5CA6D2 ;border-radius:10px"
        >
          <v-row>
            <v-col
              cols="6"
              class="d-flex justify-start"
            >
              {{ type === 0 ? $t('combobox.manage_by_factory') : $t('combobox.manage_by_job') }}
            </v-col>
            <v-col
              cols="6"
              class="d-flex justify-end"
            >
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#5CA6D2"
                @click="$router.push('/revenue-edit/' + $route.params.id)"
              >
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="type === 1"
            class="d-flex justify-center"
          >
            <v-data-table
              :headers="headers"
              :items="itemsWithIndex"
              class="elevation-1"
              :loading-text="$t('table.messages.loading')"
              :loading="loading"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 30, 50],
                showFirstLastPage: false,
                'items-per-page-text': $t('table.messages.items_per_page'),
              }"
            >
              <template v-slot:item.manageByShift="{ item }">
                <v-row class="d-flex justify-center">
                  <v-checkbox
                    v-model="item.manageByShift"
                    hide-details
                    readonly
                  />
                </v-row>
              </template>
              <template v-slot:item.shift="{ item }">
                <template v-if="item.manageByShift === true">
                  <v-row
                    v-for="(shift, index) in item.shifts"
                    :key="index"
                    class="mt-2"
                  >
                    <v-col>
                      <span>{{ shift.shiftStartAt + ' ~ ' + shift.shiftEndAt }}</span>
                    </v-col>
                  </v-row>
                </template>
              </template>
              <template v-slot:item.hourlyRevenue="{ item }">
                <template v-if="item.manageByShift === true">
                  <v-row
                    v-for="(shift, index) in item.shifts"
                    :key="index"
                    class="mt-n8"
                  >
                    <v-col>
                      <v-text-field
                        v-model="shift.hourlyRevenue"
                        class="centered-input"
                        readonly
                        type="number"
                        :suffix="$t('suffix.money')"
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-text-field
                    v-model="item.hourlyRevenue"
                    readonly
                    type="number"
                    class="centered-input"
                    :suffix="$t('suffix.money')"
                    hide-details="auto"
                  />
                </template>
              </template>
              <template v-slot:item.note="{ item }">
                <v-text-field
                  v-model="item.note"
                  readonly
                  hide-details="auto"
                />
              </template>
              <template slot="no-data">
                {{ $t('table.messages.no_data') }}
              </template>
              <template
                v-slot:footer.page-text
                class="mr-0"
              >
                {{ $t('table.messages.page') }}
                {{ options.page }}
              </template>
              <template v-slot:footer.prepend />
            </v-data-table>
          </v-row>
          <v-row
            v-else
            class="d-flex justify-center align-start"
          >
            <v-col
              cols="7"
              class="d-flex align-start"
            >
              <span class="blue--text">{{ $t('messages.contract') }}</span>
              <v-text-field
                v-model="hourlyRevenue"
                readonly
                class="mt-n4 ml-5 shrink"
                solo
                style="width:100px"
                type="number"
                :suffix="$t('suffix.money')"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center ml-n15 mr-15"
            >
              <span class="blue--text">{{ $t('messages.note') }}</span>
              <v-text-field
                v-model="note"
                readonly
                class="mt-n4 ml-5 shrink"
                solo
                style="width:40%"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'

  export default {
    name: 'RevenueManagementDetail',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        name: '',
        startAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        menuStart: false,
        menuEnd: false,
        options: {},
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'center',
            value: 'index',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.job'),
            align: 'center',
            value: 'job',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.manageByShift'),
            align: 'center',
            value: 'manageByShift',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.shift'),
            align: 'center',
            value: 'shift',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.hourlyRevenue'),
            align: 'center',
            value: 'hourlyRevenue',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.remarks'),
            align: 'center',
            value: 'note',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
        ],
        methodsList: [
          {
            text: this.$t('combobox.manage_by_factory'),
            value: 0,
          },
          {
            text: this.$t('combobox.manage_by_job'),
            value: 1,
          },
        ],
        type: 1,
        editedIndex: -1,
        items: [],
        loading: false,
        totalItems: 1,
        hourlyRevenue: 0,
        note: '',
      }
    },
    computed: {
      ...get('customer', ['customerDetail']),
      ...get('revenue', ['revenueDetail', 'listJobs']),
      itemsWithIndex () {
        return this.items.map((items, index) => ({
          ...items,
          index: index + 1,
        }))
      },
    },
    watch: {
      status (value) {
        this.loading = value === 'loading'
      },
      error (value) {
        this.showSnackBar(value)
      },
      customerDetail (value) {
        this.name = value.user.name
      },
      revenueDetail (value) {
        if (value) {
          this.startAt = moment(value[0].startAt).format('YYYY-MM-DD')
          this.endAt = moment(value[0].endAt).format('YYYY-MM-DD')
          this.type = value[0].type
          if (this.type === 0) {
            this.hourlyRevenue = value[0].hourlyRevenue
            this.note = value[0].note
          }
        }
      },
      listJobs (value) {
        if (value) {
          value.map(item => {
            if (item.isUsed === true && item.hourlyRevenue) {
              this.items.push({
                job: item.name,
                manageByShift: false,
                hourlyRevenue: item.hourlyRevenue,
                note: item.note,
                isUsed: item.isUsed,
                shifts: item.shifts,
              })
            }
            if (item.isUsed === true && !item.hourlyRevenue) {
              this.items.push({
                job: item.name,
                manageByShift: true,
                note: item.note,
                isUsed: item.isUsed,
                shifts: item.shifts,
              })
            }
          })
        }
      },
    },
    created () {
      var dataSearch = JSON.parse(localStorage.getItem('dataSearch'))
      this.$store.dispatch('customer/getCustomer', {
        id: this.$route.params.id,
        includeAll: 1,
        forRevenue: 1,
      })
      this.$store.dispatch('revenue/getDetailRevenue', dataSearch)
    },
    mounted () {},
    methods: {
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
  }
</script>
<style scoped>
  .centered-input >>> input {
    text-align: center
  }
</style>
